body {
  font-size: 16px;
  margin: 0;
  font-family: 'Poppins', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  color: #1A253B;
}

.error-text {
  font-size: 0.625rem;
  color: #E82A63;
  margin: 0.25rem 0 0;
}